.users-table {
    .table-row {
        cursor: default;
        border-top: 1px solid $grey-400;
    }

    .table-row:hover {
        padding-top: 1px;
        border-top: none;
        border-radius: .375rem;
        background-color: $grey-200;

        +div {
            padding-top: 1px;
            border-top: none;
        }
    }

    .empty-row {
        height: 2.25rem;
    }
}