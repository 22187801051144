.dot {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    display: inline-block;
}

.dot-blue {
    height: 12px;
    width: 12px;
    background-color: $bright-blue;
    border-radius: 50%;
    display: inline-block;
}

.dot-yellow {
    height: 12px;
    width: 12px;
    background-color: $yellow;
    border-radius: 50%;
    display: inline-block;
}

.dot-cyan {
    height: 12px;
    width: 12px;
    background-color: $cyan;
    border-radius: 50%;
    display: inline-block;
}

.dot-orange {
    height: 12px;
    width: 12px;
    background-color: $orange;
    border-radius: 50%;
    display: inline-block;
}

.dot-grey {
    height: 12px;
    width: 12px;
    background-color: $grey-200;
    border-radius: 50%;
    display: inline-block;
}

.text-no-wrap {
    white-space: nowrap;
}

.line {
    height: 2px;
    background-color: $grey-300;
    margin-top: 0.4rem;
}

.pie {
    border-radius: 50%;
    background-image:
        linear-gradient(200deg, transparent 50%, #d1d1d1 50%),
        linear-gradient(90deg, #d1d1d1 50%, transparent 50%);
}

@media (min-width: 576px) {
    .pie {
        width: 13rem;
        height: 13rem;
    }
}

.pie-chart-height {
    height: 280px;
}

@media (max-width: 576px) {
    .pie {
        width: 8rem;
        height: 8rem;
    }

    .pie-chart-height {
        height: 250px;
    }

    div:has(> .chart-tooltip) {
        position: absolute;
        left: 50% !important;
        top: 40% !important;
        transform: translate(-50%, -50%) !important;
        width: 300px;
    }
}

.chart-overlay {
    position: relative;
    margin-top: -30px;
    height: 20px;
}

.tooltip-width {
    max-width: 300px;
}

.line-chart-tile {
    height: 320px;
}

@media (max-width: 772px) {
    .line-chart-tile {
        height: 400px;
    }
}