body {
    font-family: 'Source Sans Pro', sans-serif;
}

h1 {
    font-size: 2rem;
    font-weight: 700;
}

h2 {
    font-size: 1.5rem;
    font-weight: 600;
}

.fw-semi-bold {
    font-weight: 600;
}

.text-sm {
    font-size: 0.75rem;
}

.text-md {
    font-size: 1rem;
}