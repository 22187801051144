.fade-out {
    animation: fadeOut 1.5s;
}

.fade-in {
    animation: fadeIn 1s;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}