.panel-item {
    cursor: pointer;
    width: 96% !important;
}

.panel-item:hover {
    background-color: $grey-200;
}

.panel-item.selected {
    background-color: $grey-200;
    order: -1;
}

.panel-item.checked {
    background-color: $grey-200;
}

@media (min-width: 992px) {
    .overlay {
        width: 232px;
        min-width: 232px;
    }
}

@media (max-width: 992px) {
    .overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: white;
        z-index: 10;
    }
}