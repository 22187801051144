.custom-dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  cursor: pointer;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-menu {
  margin-left: -55px;
  display: none;
  position: absolute;
  background-color: $white;
  color: $black-100;
  border-radius: 8px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.10);
}

.dropdown-menu li {
  cursor: pointer;
}

.dropdown-menu li:hover {
  background-color: $grey-200;
  border-radius: 8px;
}

.custom-dropdown.open .dropdown-menu {
  display: block;
}

.dropdown-item.active {
  background-color: $white;
  color: $black-100;
}

.info-tooltip .tooltip-text {
  visibility: hidden;
  width: 210px;
  background-color: $dark-blue;
  color: #fff;
  border-radius: 4px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 50%;
  text-align: left;
  margin-left: -105px;
}

@media (min-width: 576px) {
  .info-tooltip .tooltip-text::after {
    border-color: transparent transparent $dark-blue transparent;
  }
}

@media (max-width: 576px) {
  .info-tooltip .tooltip-text::after {
    border-color: transparent transparent transparent transparent;
  }

  .info-tooltip .tooltip-text-margin {
    margin-left: -50px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.info-tooltip {
  position: relative;
  display: inline-block;
}

.info-tooltip .tooltip-text::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
}

.info-tooltip:hover .tooltip-text {
  visibility: visible;
}

.rs-stack-item .rs-btn-link {
  color: $dark-blue;
  cursor: default;
  opacity: 1;
  text-wrap: balance;
  text-align: start;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  color: white;
  background-color: $light-blue;
}

.rs-btn-primary {
  color: white;
  background-color: $light-blue;
}