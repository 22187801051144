.btn-regular {
    width: 100%;
    border-radius: 12px;
    color: $bright-blue;
    background-color: white;
    border: 1.5px solid $bright-blue;
}

.btn-regular:hover {
    background-color: $grey-200;
}

.btn-regular:active {
    color: $light-blue;
    border: 1.5px solid $light-blue;
    background-color: white;
}

.btn-regular:disabled,
.btn-regular[disabled] {
    border: 1.5px solid $grey-600;
    background-color: white;
    color: $grey-600;
}