.form-check-input:checked {
	background-color: $bright-blue;
	border-color: $bright-blue;
}

.form-check-input:not([role="switch"])[type="checkbox"] {
	height: 16px;
	width: 16px;
	border: 1.5px solid $grey-600;
}

.input-group-prepend .input-group-text {
	padding: 1px;
}

/* width */
::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: $grey-400;
	border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

text {
	cursor: default;
	color: $grey-800;
}