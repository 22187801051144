@media (min-width: 768px) {
  .modal-dialog {
    min-height: 80%;
    max-width: 80%;
  }
}

@media (max-width: 768px) {
  .modal-dialog {
    min-height: 100%;
    max-width: 100%;
  }
}

.modal-content {
  background-color: #f8f9fa;
  border: none;
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  height: 100%;
}

.modal-overflow {
  overflow-y: auto;
  height: 55vh;
}

.link-span {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}