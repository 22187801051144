$black-100: #1C1C1C;

$white: #FFFFFF;

$grey-200: #F1F1F1;
$grey-300: #E6E6E6;
$grey-400: #D2D2D2;
$grey-600: #818181;
$grey-800: #575757;

$light-blue: #35A2CD;
$dark-blue: #1A263F;
$bright-blue: #1B598E;
$yellow: #F1C85E;
$green: #198754;
$bright-red: #F8D7DA;
$red: #DC3545;
$dark-red: #842029;
$orange: #EBA059;
$cyan: #0B8ABD;
$light-yellow: #FDEDBB;

$header-height: 59.237px;