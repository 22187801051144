.react-tooltip {
  z-index: 100;
  width: 245px !important;
  border-radius: 6px !important;
  text-align: center !important;
  padding: 0 !important;
  opacity: 1 !important;
}

.users-tooltip {
  background-color: $white !important;
  color: $black-100 !important;
  border-radius: 6px !important;
}

.customers-tooltip {
  background-color: $black-100 !important;
  color: $white !important;
  padding: 6px !important;
  border-radius: 6px !important;
}

