.height-100 {
    height: 100svh;
}

@media (min-width: 768px) {
    .height-md-100 {
        height: calc(100vh - $header-height);
    }

    .height-100 {
        height: calc(100vh - $header-height);
    }
}


@media (min-width: 768px) {
    .tile {
        height: 335px;

        .legend {
            height: 240px;
        }
    }
}

.customers-list {
    overflow-x: hidden !important;
}