.c-black-100 {
    color: $black-100;
}

.bc-black-100 {
    color: $black-100;
}

.c-grey-200 {
    color: $grey-200;
}

.bc-grey-200 {
    background-color: $grey-200;
}

.c-grey-400 {
    color: $grey-400;
}

.bc-grey-400 {
    background-color: $grey-400;
}

.c-grey-600 {
    color: $grey-600;
}

.bc-grey-600 {
    background-color: $grey-600;
}

.c-grey-800 {
    color: $grey-800;
}

.bc-grey-800 {
    background-color: $grey-800;
}

.c-dark-blue {
    color: $dark-blue;
}

.bc-dark-blue {
    background-color: $dark-blue;
}

.c-bright-blue {
    color: $bright-blue;
}

.bc-bright-blue {
    background-color: $bright-blue;
}

.c-yellow {
    color: $yellow;
}

.bc-yellow {
    background-color: $yellow;
}

.bc-white {
    background-color: $white;
}

.c-green {
    color: $green;
}

.bc-bright-red {
    background-color: $bright-red;
}

.c-red {
    color: $red;
}

.c-dark-red {
    color: $dark-red;
}

.bc-light-yellow {
    background-color: $light-yellow;
}

.c-grey-300 {
    color: $grey-300;
}